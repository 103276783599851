import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Masonry from '../components/Masonry';
import Card from '../components/Card';
import Headline from '../components/Headline';
import Skills from '../components/Skills';
import Publications from '../components/Publications';
import Layout from '../components/Layout';
import WebGLBackground from '../components/WebGLBackground';
import './index.css';


export default () => {
  return (
    <Layout>
      <SEO title='Publications' />
      <Headline text="Publications" color="#5AF78E" />
      <div
        style={{
          padding: '30px 0px',
        }}
      >
        <Publications />
      </div>
    </Layout>
  );
};

